export enum BlockInputOutputType {
  File = 'file',
  Text = 'text',
  Image = 'image',
  Audio = 'audio',
  Data = 'data',
  Vector = 'vector',
  Entity = 'entity',
  Relation = 'relation',
  Search = 'search',
  Index = 'index',
  Knowledge = 'knowledge',
  Skill = 'skill',
  Context = 'context',
}

export enum BlockType {
  // Trigger
  ObjectStorageUpload = 'object-storage-upload',
  ExternalStorageUpload = 'external-storage-upload',
  // Storage
  ObjectStorage = 'object-storage',
  KeyValueDatabase = 'key-value-database',
  VectorDatabase = 'vector-database',
  GraphDatabase = 'graph-database',
  // Model
  Model = 'model',
  // Agent
  Agent = 'agent',
  // Data Collection
  DataCollection = 'data-collection',
  Knowledge = 'knowledge',
  // Function
  DocumentParser = 'document-parser',
  ChunkSplitter = 'chunk-splitter',
  SentenceSplitter = 'sentence-splitter',
  WordSplitter = 'word-splitter',
  EntityExtractor = 'entity-extractor',
  RelationExtractor = 'relation-extractor',
  Reranker = 'reranker',
  // System
  Prompt = 'prompt',
  Context = 'context',
  Answer = 'answer',
  // Skill
  AgentSkill = 'agent-skill',
  Time = 'time',
  Code = 'code',
  Search = 'search',
  WebScraper = 'web-scraper',
  WebScraperRaw = 'web-scraper-raw',
  CustomSkill = 'custom-skill',
}

export interface Blocks {
  agents: Block;
  dataCollections: Block;
  functions: Block;
  knowledges: Block;
  models: Block;
  outputs: Block;
  skills: Block;
  storages: Block;
  triggers: Block;
}

export interface Block {
  [key: string]: BlockConfig;
}

interface BlockIOTypes {
  name: string;
  inputTypes?: BlockInputOutputType[];
  outputTypes?: BlockInputOutputType[];
  attributes?: { [key: string]: string | number | boolean; } | undefined;
}

interface BlockConfig {
  ingest?: BlockIOTypes;
  retrieve?: BlockIOTypes;
  workflow?: BlockIOTypes;
  [key: string]: any;
}

export interface BlockAttributes {
  [key: string]: string;
}
