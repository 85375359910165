import { Injectable } from '@angular/core';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { User } from 'src/app/models/user';

import { DataStoreService } from 'src/app/services/data-store/data-store.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { updateProfile, verifyBeforeUpdateEmail } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private firebase: FirebaseService,
    private dataStore: DataStoreService,
  ) {}

  async getCurrentUserDocument(forceFetch = false) {
    const inMemoryUser = this.dataStore.getProperty('currentUser');
    if (!forceFetch && inMemoryUser && inMemoryUser.userId) {
      return inMemoryUser;
    }

    await this.firebase.auth.authStateReady();
    const authUser = this.firebase.auth.currentUser;
    if (!authUser) {
      throw new Error('User not found');
    }

    await this.manageAWSFulfillment(authUser.uid);

    const userDoc = await getDoc(doc(this.firebase.firestore, `users/${authUser.uid}`));
    const currentUser = userDoc.data() as User;
    if (!currentUser.userId) {
      currentUser.userId = authUser.uid;
    }
    this.dataStore.updateState({ currentUser });

    return currentUser;
  }

  async getCurrentAccountId(): Promise<string> {
    const { currentAccountId, defaultAccountId } = await this.getCurrentUserDocument();
    if (!currentAccountId) {
      if (defaultAccountId) {
        return defaultAccountId;
      }

      // You probably did not accept the license yet..
      // WESHOULD - Manage this better
      return '';
    }

    return currentAccountId;
  }

  async getDefaultAccountId(): Promise<string> {
    const { defaultAccountId } = await this.getCurrentUserDocument();
    if (!defaultAccountId) {
      throw new Error('Default account ID not found');
    }
    return defaultAccountId;
  }

  async updateDisplayName(displayName: string): Promise<void> {
    const user = this.firebase.auth.currentUser;
    if (!user) {
      throw new Error('User not found');
    }

    await updateProfile(user, { displayName });
  }

  async hasAcceptedLicense(): Promise<boolean> {
    const currentUser = await this.getCurrentUserDocument(true);
    return currentUser.licenseAccepted;
  }

  async updateEmail(email: string): Promise<void> {
    const user = this.firebase.auth.currentUser;
    if (!user) {
      throw new Error('User not found');
    }

    await verifyBeforeUpdateEmail(user, email);
  }

  async updateThemePreference(theme: 'light' | 'dark'): Promise<void> {
    const user = this.firebase.auth.currentUser;
    if (!user) {
      throw new Error('User not found');
    }

    await setDoc(doc(this.firebase.firestore, `users/${user.uid}`), { theme }, { merge: true });

    const currentUser = await this.getCurrentUserDocument();
    currentUser.theme = theme;
  }

  async getThemePreference(): Promise<'light' | 'dark' | undefined> {
    const currentUser = await this.getCurrentUserDocument();
    return currentUser?.theme;
  }

  private async manageAWSFulfillment(userId: string) {
    const awsFulfillment = this.dataStore.getSessionStateProperty('awsFulfillment');

    if (awsFulfillment) {
      await setDoc(doc(this.firebase.firestore, 'users', userId), {
        awsFulfillment,
      }, { merge: true });
      this.dataStore.removeSessionStateProperty('awsFulfillment');
    }
  }

  async getLastViewedChat(): Promise<{ accountId: string, vpmId: string; workflowId: string } | null> {
    const userId = this.firebase.auth.currentUser?.uid;
    if (!userId) {
      throw new Error('User not found');
    }

    const userDoc = await getDoc(doc(this.firebase.firestore, 'users', userId));
    const settings = userDoc.exists() ? userDoc.data()['settings'] || {} : {};

    return settings.lastViewedChat || null;
  }

  async setLastViewedChat(chatInfo: {accountId: string, vpmId: string; workflowId: string }): Promise<void> {
    await this.getCurrentUserDocument();

    const userId = this.firebase.auth.currentUser?.uid;
    if (!userId) {
      throw new Error('User not found');
    }

    const userRef = doc(this.firebase.firestore, 'users', userId);
    await updateDoc(userRef, {
      'settings.lastViewedChat': chatInfo,
    });
  }

  async getLanguagePreference(): Promise<string | undefined> {
    const currentUser = await this.getCurrentUserDocument();
    return currentUser?.language;
  }

  async updateLanguagePreference(language: string): Promise<void> {
    const user = this.firebase.auth.currentUser;
    if (!user) {
      throw new Error('User not found');
    }

    await setDoc(doc(this.firebase.firestore, `users/${user.uid}`), { language }, { merge: true });

    const currentUser = await this.getCurrentUserDocument();
    currentUser.language = language;
  }
}
