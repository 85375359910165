import { enableProdMode, SecurityContext, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';

import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { MARKED_OPTIONS, MarkedOptions, MarkedRenderer, provideMarkdown } from 'ngx-markdown';

import { routes } from 'src/app/app.routes';
import { AppComponent } from 'src/app/app.component';

import { environment } from 'src/environments/environment';
import { provideHttpClient } from '@angular/common/http';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';

if (environment.production) {
  enableProdMode();
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const linkRenderer = renderer.link;
  const codeRenderer = renderer.code;

  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" ');
  };

  renderer.code = (code, language, isEscaped) => {
    let html = codeRenderer.call(renderer, code, language, isEscaped);

    if (language === 'html') {
      const escapedCode = code
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');

      const toggleButtons = `
        <div class="code-block-buttons-container">
          <div class="code-block-buttons">
            <button class="code-block-button" onclick="this.parentElement.parentElement.nextElementSibling.style.display = 'block'; this.nextElementSibling.style.display = 'block'; this.parentElement.parentElement.nextElementSibling.nextElementSibling.style.display = 'none'; this.style.display = 'none';" style="display: block">Preview HTML</button>
            <button class="code-block-button" onclick="this.parentElement.parentElement.nextElementSibling.nextElementSibling.style.display = 'block'; this.previousElementSibling.style.display = 'block'; this.parentElement.parentElement.nextElementSibling.style.display = 'none'; this.style.display = 'none';" style="display: none">View Code</button>
          </div>
        </div>
      `

      html = `${toggleButtons}<iframe srcdoc="${escapedCode}"></iframe>${html}`;
    }

    return html;
  };

  return {
    renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
  };
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({
      mode: 'md',
    }),
    provideRouter(routes),
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      },
      sanitize: SecurityContext.NONE,
    }),
    provideHttpClient(),
    provideTransloco({
        config: {
          availableLangs: ['en', 'fr'],
          defaultLang: 'en',
          // Remove this option if your application doesn't support changing language in runtime.
          reRenderOnLangChange: true,
          prodMode: !isDevMode(),
        },
        loader: TranslocoHttpLoader
      })
  ],
});
