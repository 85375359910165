import { Block, BlockInputOutputType } from 'src/app/models/blocks/base';

export function getModelBlocks(): Block {
  return {
    template: {
      ingest: {
        name: 'Run',
        inputTypes: [BlockInputOutputType.Index],
        outputTypes: [],
      },
      retrieve: {
        name: 'Run',
        inputTypes: [BlockInputOutputType.Search],
        outputTypes: [],
      },
    },
  };
}
