<ion-app>
  <ion-router-outlet [animated]="false"></ion-router-outlet>
  <svg>
    <defs>
      <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
        <polygon class="arrow-head" points="0 0, 10 3.5, 0 7" />
      </marker>
    </defs>
  </svg>
</ion-app>
