import { BlockAttributes, Blocks } from 'src/app/models/blocks/base';

import { getAgentBlocks } from 'src/app/models/blocks/agent';
import { getDataCollectionBlocks } from 'src/app/models/blocks/data-collection';
import { getFunctionBlocks } from 'src/app/models/blocks/function';
import { getKnowledgeBlocks } from 'src/app/models/blocks/knowledge';
import { getModelBlocks } from 'src/app/models/blocks/model';
import { getOutputBlocks } from 'src/app/models/blocks/output';
import { getSkillBlocks } from 'src/app/models/blocks/skill';
import { getStorageBlocks } from 'src/app/models/blocks/storage';
import { getTriggerBlocks } from 'src/app/models/blocks/trigger';

export function getBlockAttributes(): BlockAttributes {
  return {
    size: 'Max Characters',
    overlap: 'Overlap',
    ner: 'Named Entities',
    noun: 'Nouns',
    propn: 'Proper Nouns',
    verb: 'Verbs',
    minRelevance: 'Min Relevance',
    topK: 'Max Results',
    contentSize: 'Context Size',
    maxDepth: 'Max Depth',
  };
}

export function getBlocks(): Blocks {
  return {
    agents: getAgentBlocks(),
    dataCollections: getDataCollectionBlocks(),
    functions: getFunctionBlocks(),
    knowledges: getKnowledgeBlocks(),
    models: getModelBlocks(),
    outputs: getOutputBlocks(),
    skills: getSkillBlocks(),
    storages: getStorageBlocks(),
    triggers: getTriggerBlocks(),
  };
};
