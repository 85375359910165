import { Block, BlockInputOutputType } from 'src/app/models/blocks/base';

export function getTriggerBlocks(): Block {
  return {
    'object-storage-upload': {
      ingest: {
        name: 'Upload file to',
        outputTypes: [BlockInputOutputType.File],
      },
    },
    'external-storage-upload': {
      ingest: {
        name: 'Upload file to',
        outputTypes: [BlockInputOutputType.File],
      },
    },
    prompt: {
      retrieve: {
        name: 'Get a prompt from user',
        outputTypes: [BlockInputOutputType.Text],
      },
      workflow: {
        name: 'Get a prompt from user',
        outputTypes: [BlockInputOutputType.Text],
      },
    }
  };
}
