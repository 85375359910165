import { Block, BlockInputOutputType } from 'src/app/models/blocks/base';

export function getKnowledgeBlocks(): Block {
  return {
    template: {
      workflow: {
        name: 'Retrieve context from',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Knowledge],
      },
    },
  };
}
