import { Injectable, model } from '@angular/core';

import { collection, getDocs, query, serverTimestamp, Timestamp, where } from 'firebase/firestore';

import { getResourceVersion } from 'src/app/helpers/workflow';

import { BlockType } from 'src/app/models/blocks/base';
import { Resource } from 'src/app/models/resources';
import { MistletResource } from 'src/app/models/resources/mistlet';
import { Workflow } from 'src/app/models/resources/workflow';

import { DataCollection } from 'src/app/pages/data-collection/data-collection.page';

import { AccountService } from 'src/app/services/account/account.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  constructor(
    private account: AccountService,
    private firebase: FirebaseService,
    private resources: ResourcesService,
  ) {}

  async getDefaultWorkflow(accountId?: string): Promise<Workflow> {
    const workflows = await this.resources.get(Resource.Workflow, accountId) as Workflow[];

    if (!workflows || workflows.length === 0) {
      throw new Error('No workflows found');
    }

    const activeWorkflow = workflows.find(workflow => workflow.deployed && !workflow.deploying);

    if (!activeWorkflow) {
      const workflow = workflows[0];

      return workflow;
    }

    return activeWorkflow;
  }

  async getWorkflows(): Promise<Workflow[]> {
    const workflows = await this.resources.get(Resource.Workflow) as Workflow[];

    return workflows;
  }

  async waitForDeployment(workflowId: string): Promise<void> {
    const account = await this.account.getCurrentAccount();
    let now = Date.now();

    return new Promise<void>((resolve) => {
      const interval = setInterval(async () => {
        const timestamp = Timestamp.fromMillis(now);
        const ref = collection(this.firebase.firestore, `developers/${account.vendorId}/stats`);
        const snapshot = await getDocs(query(ref, where('timestamp', '>=', timestamp)));
        for (const doc of snapshot.docs) {
          const data = doc.data();
          if (data['nodeType'] === 'agent' && data['report']['generativeAIWorkers'][workflowId]) {
            clearInterval(interval);
            resolve();
          }
        }
        now = Date.now();
      }, 1000);
    });
  }

  async updateResources(vpmId: string): Promise<void> {
    const mistlets = await this.resources.get(Resource.Mistlet);
    for (const mistlet of mistlets) {
      if (!mistlet.clusters) {
        continue;
      }
      const vpm = mistlet.clusters.find((cluster: any) => cluster.id === vpmId);
      if (!vpm) {
        continue;
      }

      const resources = await this.getResources(vpmId);
      await this.resources.update(Resource.Mistlet, mistlet.id, {
        timestamp: serverTimestamp(),
        clusters: mistlet.clusters.map((cluster: any) => {
          if (cluster.id === vpmId) {
            return {
              ...cluster,
              pubSubTopics: resources.pubSubTopics,
              objectStorageBuckets: resources.objectStorageBuckets,
              modelStorageBuckets: resources.modelStorageBuckets,
              externalStorageConnections: resources.externalStorageConnections,
              keyValueDatabases: resources.keyValueDatabases,
              vectorDatabases: resources.vectorDatabases,
              graphDatabases: resources.graphDatabases,
              generativeAIContexts: resources.generativeAIContexts,
            };
          }
          return cluster;
        }),
      });
    }
  }

  private async getResources(vpmId: string): Promise<MistletResource> {
    const resources: MistletResource = {
      pubSubTopics: [],
      objectStorageBuckets: [],
      modelStorageBuckets: [],
      externalStorageConnections: [],
      keyValueDatabases: [],
      vectorDatabases: [],
      graphDatabases: [],
      generativeAIContexts: [],
    };

    const workflows: Workflow[] = await this.resources.get(Resource.Workflow);
    for (const workflow of workflows) {
      if (!(workflow.deployed || workflow.deploying) || workflow.vpmId !== vpmId) {
        continue;
      }

      resources.generativeAIContexts.push(workflow.id);

      if (!resources.modelStorageBuckets.includes(workflow.bucketId)) {
        resources.modelStorageBuckets.push(workflow.bucketId);
      }

      const deployedWorkflow = getResourceVersion(workflow, workflow.deployedVersion);
      const collections: DataCollection[] = await this.resources.get(Resource.Collection);
      for (const block of deployedWorkflow.blocks ?? []) {
        if (block.type === BlockType.DataCollection || block.type === BlockType.Knowledge) {
          const collection = collections.find((collection) => collection.id === block.id);
          if (!collection) {
            continue;
          }

          const deployedCollection = getResourceVersion(collection, block.version);
          for (const collectionBlock of [...(deployedCollection.ingestBlocks ?? []), ...(deployedCollection.retrieveBlocks ?? [])]) {
            if (collectionBlock.type === BlockType.ObjectStorage) {
              if (!resources.objectStorageBuckets.includes(collectionBlock.id)) {
                resources.objectStorageBuckets.push(collectionBlock.id);
              }
            }
            if (collectionBlock.type === BlockType.ObjectStorageUpload) {
              if (!resources.objectStorageBuckets.includes(collectionBlock.id)) {
                resources.objectStorageBuckets.push(collectionBlock.id);
              }
            }
            if (collectionBlock.type === BlockType.ExternalStorageUpload) {
              if (!resources.externalStorageConnections.includes(collectionBlock.id)) {
                resources.externalStorageConnections.push(collectionBlock.id);
              }
            }
            if (collectionBlock.type === BlockType.KeyValueDatabase) {
              if (!resources.keyValueDatabases.includes(collectionBlock.id)) {
                resources.keyValueDatabases.push(collectionBlock.id);
              }
            }
            if (collectionBlock.type === BlockType.VectorDatabase) {
              if (!resources.vectorDatabases.includes(collectionBlock.id)) {
                resources.vectorDatabases.push(collectionBlock.id);
              }
            }
            if (collectionBlock.type === BlockType.GraphDatabase) {
              if (!resources.graphDatabases.includes(collectionBlock.id)) {
                resources.graphDatabases.push(collectionBlock.id);
              }
            }
          }
        }
      }
    }

    return resources;
  }
}
