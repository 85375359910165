import { Injectable } from '@angular/core';
import { doc, collection, serverTimestamp, setDoc, getDoc, query, startAfter, limit, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import type { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';

import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';

export enum OrganizationRole {
 Admin = 'admin',
 Member = 'member',
 ChatUser = 'chatUser',
 ContentManager = 'contentManager',
}

export type OrganizationMembersPage = {
  members: OrganizationMember[];
  lastVisible: QueryDocumentSnapshot<DocumentData> | null;
};

export type OrganizationMember = {
  userId: string;
  role: OrganizationRole;
  permissions: string[];
  joinedAt: any;
};

export type MemberDetails = { [id: string]: { email: string, displayName: string }}

@Injectable({
  providedIn: 'root'
})
export class OrganizationMemberService {

  constructor(
    private firebase: FirebaseService,
    private organizationService: OrganizationService,
  ) { }

  async getCurrentMember(organizationId: string): Promise<OrganizationMember> {
    if (!this.firebase.auth.currentUser) {
      throw new Error('User not logged in');
    }

    return this.getOrganizationMember(organizationId, this.firebase.auth.currentUser.uid);
  }

  async getOrganizationMembers(organizationId: string, limitPerPage: number, lastDoc?: QueryDocumentSnapshot<DocumentData> | null): Promise<OrganizationMembersPage> {
    const organizationRef = doc(this.firebase.firestore, 'organizations', organizationId);
    const membersRef = collection(organizationRef, 'members');

    let memberQuery = query(membersRef, limit(limitPerPage));
    if (lastDoc) {
      memberQuery = query(memberQuery, startAfter(lastDoc));
    }

    const querySnapshot = await getDocs(memberQuery);
    const members = querySnapshot.docs.map(doc => this.mapMemberData(doc.data()));

    if (querySnapshot.size < limitPerPage) {
      return { members, lastVisible: null };
    }

    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1] || null;

    return { members, lastVisible };
  }


  async listMembersDetailsOfOrganization(organizationId: string): Promise<MemberDetails> {
    const { functions } = this.firebase;

    const listMembersDetailsOfOrganizationCallable = httpsCallable<{ organizationId: string }, { users: MemberDetails }>(functions, 'listMembersDetailsOfOrganization');

    try {
      const result = await listMembersDetailsOfOrganizationCallable({
        organizationId,
      });

      return result.data.users;
    } catch (error) {
      console.error('Error fetching user details:', error);
      throw new Error('Error fetching user details');
    }
  }

  async getOrganizationMember(organizationId: string, memberId: string): Promise<OrganizationMember> {
    const organizationRef = doc(this.firebase.firestore, 'organizations', organizationId);
    const memberRef = doc(organizationRef, 'members', memberId);
    const memberSnapshot = await getDoc(memberRef);

    if (!memberSnapshot.exists()) {
      throw new Error('Member not found');
    }

    return this.mapMemberData(memberSnapshot.data());
  }

  async addMemberToOrganization(organizationId: string, role: OrganizationRole, userId?: string) {
    if (!this.firebase.auth.currentUser) {
      throw new Error('User not logged in');
    }

    if (!userId) {
      userId = this.firebase.auth.currentUser.uid;
    }

    const db = this.firebase.firestore;
    const organizationRef = doc(db, 'organizations', organizationId);
    const memberRef = doc(organizationRef, 'members', this.firebase.auth.currentUser.uid);

    setDoc(memberRef, {
      userId,
      role,
      joinedAt: serverTimestamp(),
    });

    this.organizationService.addOrganizationToUser(this.firebase.auth.currentUser.uid, organizationId);
  }

  async updateMemberRole(organizationId: string, memberId: string, role: OrganizationRole) {
    const isAdmin = await this.isCurrentUserAdmin(organizationId);
    if (!isAdmin) {
      throw new Error('Only organization admins can update members');
    }


    const db = this.firebase.firestore;
    const organizationRef = doc(db, 'organizations', organizationId);
    const memberRef = doc(organizationRef, 'members', memberId);

    await setDoc(memberRef, { role }, { merge: true });
  }

  async removeMemberFromOrganization(organizationId: string, memberId: string) {
    if (memberId === this.firebase.auth.currentUser?.uid) {
      throw new Error('Cannot remove yourself from the organization');
    }

    const isAdmin = await this.isCurrentUserAdmin(organizationId);
    if (!isAdmin) {
      throw new Error('Only organization admins can remove members');
    }

    return this.removeMemberFromOrganizationFunction(organizationId, memberId);
  }

  async isCurrentUserAdmin(organizationId: string): Promise<boolean> {
    if (!this.firebase.auth.currentUser) {
      return false;
    }

    const member = await this.getOrganizationMember(organizationId, this.firebase.auth.currentUser.uid);
    return member.role === OrganizationRole.Admin;
  }

  // TODO - rewrite this using converters
  private mapMemberData(memberData?: DocumentData): OrganizationMember {
    if (!memberData) {
      throw new Error('Member data is required');
    }

    return {
      userId: memberData['userId'],
      role: memberData['role'],
      permissions: memberData['permissions'],
      joinedAt: memberData['joinedAt'],
    };
  }

  async removeMemberFromOrganizationFunction(organizationId: string, userId: string) {
    const removeUserFromOrganizationCallable = httpsCallable<{ organizationId: string, userId: string }, { success: boolean }>(this.firebase.functions, 'removeUserFromOrganization');
    const result = await removeUserFromOrganizationCallable({ organizationId, userId });

    return result.data.success;
  }
}
