import { Injectable } from '@angular/core';
import { signOut } from 'firebase/auth';
import { BehaviorSubject } from 'rxjs';

import { StorageState, State } from 'src/app/models/state';

import { FirebaseService } from 'src/app/services/firebase/firebase.service';

const initialState: State = {
  authUser: null,
  currentAccount: null,
  organization: null,
  currentUser: null,
};

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  private readonly authClearProps = ['currentAccount']
  private state: State = { ...initialState };

  // TODO - Add type safety to the sessionState and authState
  private authState: StorageState = {};
  private sessionState: StorageState = {};

  private state$ = new BehaviorSubject<{ newState: State, oldState?: State }>({ newState: this.state });

  constructor(
    private firebase: FirebaseService,
  ) {
    let loggedIn = false;
    this.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        if (authUser.isAnonymous || authUser.email === null) {
          // Reset the state when the user logs out
          this.clearState();
          this.clearAuthState();
          signOut(this.firebase.auth);
          return;
        }

        this.updateState({ authUser });
        loggedIn = true;
      } else {
        // Reset the state when the user logs out
        this.clearState();
        this.clearAuthState();
        if (loggedIn) {
          loggedIn = false;
          // TODO org - Remove this reload once the pages are properly garbage collected
          window.location.reload();
        }
      }
    });
  }

  getState$() {
    return this.state$.asObservable();
  }

  getProperty<T extends keyof State>(property: T) {
    return this.state[property];
  }

  updateState(newState: Partial<State>) {
    const oldState = this.state;
    this.state = { ...this.state, ...newState };
    this.state$.next({ newState: this.state, oldState });
  }

  clearState() {
    const oldState = this.state;
    this.state = { ...initialState };
    this.state$.next({ newState: this.state, oldState });
  }

  setSessionStateProperty(key: string, value: any) {
    this.sessionState[key] = true;
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getSessionStateProperty(key: string) {
    return JSON.parse(sessionStorage.getItem(key) || 'null');
  }

  removeSessionStateProperty(key: string) {
    delete this.sessionState[key];
    sessionStorage.removeItem(key);
  }

  setAuthStateProperty(key: string, value: any, stringify = true) {
    this.authState[key] = true;
    const data = stringify ? JSON.stringify(value) : value;
    localStorage.setItem(key, data);
  }

  getAuthStateProperty(key: string, parse = true) {
    const data = localStorage.getItem(key);
    return parse ? JSON.parse(data || 'null') : data;
  }

  removeAuthStateProperty(key: string) {
    delete this.authState[key];
    localStorage.removeItem(key);
  }

  clearAuthState() {
    for (const key in this.authState) {
      localStorage.removeItem(key);
    }

    for (const prop of this.authClearProps) {
      localStorage.removeItem(prop);
    }

    this.authState = {};
  }
}
