import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private userService: UserService,
  ) { }

  async getLanguage() {
    return await this.userService.getLanguagePreference();
  }

  async setLanguage(language: string) {
    await this.userService.updateLanguagePreference(language);
  }
}
