import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { User, onAuthStateChanged } from 'firebase/auth';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

export type AuthPipeGenerator = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => any;

export const loggedIn: any = (user : User | null) => !!user;

@Injectable({
  providedIn: 'any'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private firebase: FirebaseService,
  ) {}

  canActivate = async (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authPipeFactory = next.data['authGuardPipe'] as AuthPipeGenerator || (() => loggedIn);
    const user = await this.retrieveUser();
    const can = authPipeFactory(next, state)(user);
    if (typeof can === 'boolean') {
      return can;
    } else if (Array.isArray(can)) {
      return this.router.createUrlTree(can);
    } else {
      return this.router.parseUrl(can);
    }
  };

  retrieveUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      const sub = onAuthStateChanged(this.firebase.auth, (user) => {
        sub();
        if (!user || user.isAnonymous) {
            resolve(null);
        } else {
            resolve(user);
        }
      }, () => {
        reject(null);
      });
    });
  }
}


export const redirectUnauthorizedTo: (redirect: string|any[]) => any =
  (redirect) => (user: User | null) => loggedIn(user) || redirect;
export const redirectLoggedInTo: (redirect: string|any[]) => any =
  (redirect) => (user: User | null) => loggedIn(user) && redirect || true;
