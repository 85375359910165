import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { EditorPage } from 'src/app/pages/editor/editor.page';

@Injectable({
  providedIn: 'root'
})
export class PipelineCanDeactivateGuard {

  constructor(private alertController: AlertController) {}

  async canDeactivate(component: EditorPage): Promise<boolean> {
    if (component.isEditing) {
      const alert = await this.alertController.create({
        header: 'Confirm',
        message: 'You have unsaved changes. Are you sure you want to leave?',
        cssClass: 'alert',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'button-normal',
            handler: () => {
              return false;
            }
          },
          {
            text: 'Leave',
            role: 'confirm',
            cssClass: 'button-danger',
            handler: () => {
              return true;
            }
          }
        ]
      });

      await alert.present();
      const { role } = await alert.onDidDismiss();
      return role === 'confirm';
    }
    return true;
  }
}
