import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor(
    private userService: UserService,
  ) { }

  isDarkMode() {
    const hasLocalStorageMode = window.localStorage.getItem('darkMode');
    if (!hasLocalStorageMode) {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    return hasLocalStorageMode === 'true';
  }

  async applyTheme(darkMode?: boolean, saveSetting = false, checkUser = true) {
    if (darkMode === undefined) {
      darkMode = this.isDarkMode();
    }

    if (saveSetting) {
      await this.userService.updateThemePreference(darkMode ? 'dark' : 'light');
      window.localStorage.setItem('darkMode', darkMode ? 'true' : 'false');
    }

    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }

    if (saveSetting || !checkUser) {
      return;
    }

    const recordedMode = await this.userService.getThemePreference();
    if (!recordedMode) {
      return;
    }

    const isRecordedDarkMode = recordedMode === 'dark';
    if (darkMode !== isRecordedDarkMode) {
      this.applyTheme(isRecordedDarkMode, true);
    }
  }
}
