import { Block, BlockInputOutputType } from 'src/app/models/blocks/base';

export function getSkillBlocks(): Block {
  return {
    'agent-skill': {
      workflow: {
        name: 'Run',
        inputTypes: [BlockInputOutputType.Knowledge, BlockInputOutputType.Skill],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    'custom-skill': {
      workflow: {
        name: 'Execute',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    time: {
      workflow: {
        name: 'Aware of time',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    code: {
      workflow: {
        name: 'Execute Code',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    search: {
      workflow: {
        name: 'Search the web',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    'web-scraper': {
      workflow: {
        name: 'Read a web page',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    'web-scraper-raw': {
      workflow: {
        name: 'Retrieve raw content',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
  };
}