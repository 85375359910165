import { Block, BlockInputOutputType } from 'src/app/models/blocks/base';

export function getOutputBlocks(): Block {
  return {
    context: {
      retrieve: {
        name: 'Add to context',
        inputTypes: [BlockInputOutputType.Search],
      },
    },
    answer: {
      workflow: {
        name: 'Answer the user',
        inputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Context],
      },
    }
  };
}
