import { Injectable } from '@angular/core';

import { addDoc, collection, deleteDoc, doc, getDocs, setDoc, serverTimestamp, updateDoc, getDoc } from 'firebase/firestore';

import { v4 } from 'uuid';

import { getBlocks } from 'src/app/models/block';
import { BlockInputOutputType, BlockType } from 'src/app/models/blocks/base';
import { Resource, resourceCollection } from 'src/app/models/resources';

import { AccountService } from 'src/app/services/account/account.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

const instructionsWriter = `Role: Act as Instruction Writer to Write Clear and Concise Instructions for an AI Agent

Steps:

Step 1: Analyze the user's prompt to identify the main task, key steps, and specific requirements.

Step 2: Summarize the requirements, outlining the purpose, key actions, and important details or restrictions.

Step 3: Draft instructions, using clear and concise language that the AI can easily understand.

Step 4: Review and refine the instructions to ensure they align with the AI's capabilities and limitations.

Step 5: Finalize and confirm the instructions, using concise language that the AI can easily understand and follow.

Important Notes:
- Use clear and concise language that the AI can easily understand.
- Take into account the AI's capabilities and limitations.
- Emphasize the importance of accuracy and precision in the instructions.

Example Response Format:
\`\`\`
Role: Act as [INSERT ROLE HERE] to [INSERT MAIN OBJECTIVE HERE]
Steps:
[INSERT STEPS HERE TO ACCOMPLISH THE OBJECTIVE HERE]
Important Notes:
[INSERT IMPORTANT THINGS THE AGENT NEED TO FOLLOW HERE]
\`\`\``;

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  collectionMap = {};
  private blocksDefinition = getBlocks();

  constructor(
    private firebase: FirebaseService,
    private accountService: AccountService
  ) { }

  async get(resource: Resource, accountId?: string): Promise<any[]> {
    if (!accountId) {
      const { id } = await this.accountService.getCurrentAccount();
      accountId = id;
    }
    const collectionPath = resourceCollection[resource];
    const collectionRef = collection(this.firebase.firestore, `developers/${accountId}/${collectionPath}`);
    const snapshot = await getDocs(collectionRef);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }

  async getById(resource: Resource, docId: string, accountId?: string): Promise<any> {
    if (!accountId) {
      const { id } = await this.accountService.getCurrentAccount();
      accountId = id;
    }
    const collectionPath = resourceCollection[resource];
    const docRef = doc(this.firebase.firestore, `developers/${accountId}/${collectionPath}/${docId}`);
    const docSnap = await getDoc(docRef);
    return { id: docSnap.id, ...docSnap.data() };
  }

  async add(resource: Resource, data: any, accountId?: string): Promise<string> {
    if (!accountId) {
      const { id } = await this.accountService.getCurrentAccount();
      accountId = id;
    }
    const collectionPath = resourceCollection[resource];
    const collectionRef = collection(this.firebase.firestore, `developers/${accountId}/${collectionPath}`);
    const docRef = await addDoc(collectionRef, { ...data, timestamp: serverTimestamp() });
    return docRef.id;
  }

  async set(resource: Resource, docId: string, data: any, accountId?: string): Promise<void> {
    if (!accountId) {
      const { id } = await this.accountService.getCurrentAccount();
      accountId = id;
    }
    const collectionPath = resourceCollection[resource];
    const docRef = doc(this.firebase.firestore, `developers/${accountId}/${collectionPath}/${docId}`);
    await setDoc(docRef, data, { merge: true });
  }

  async update(resource: Resource, docId: string, data: any, accountId?: string): Promise<void> {
    if (!accountId) {
      const { id } = await this.accountService.getCurrentAccount();
      accountId = id;
    }
    const collectionPath = resourceCollection[resource];
    const docRef = doc(this.firebase.firestore, `developers/${accountId}/${collectionPath}/${docId}`);
    await updateDoc(docRef, data);
  }

  async delete(resource: Resource, docId: string, accountId?: string): Promise<void> {
    if (!accountId) {
      const { id } = await this.accountService.getCurrentAccount();
      accountId = id;
    }
    const collectionPath = resourceCollection[resource];
    const docRef = doc(this.firebase.firestore, `developers/${accountId}/${collectionPath}/${docId}`);
    await deleteDoc(docRef);
  }

  async createDefaultResources(accountId: string): Promise<void> {
    const vpmId = await this.add(Resource.VPM, {
      name: 'Default VPM',
    }, accountId);

    await this.add(Resource.Mistlet, {
      name: 'Default Mistlet Group',
      clusters: [{
        id: vpmId,
        name: 'Default VPM',
      }],
    }, accountId);

    const bucketId = await this.add(Resource.ObjectStorage, {
      name: 'Models',
      vpmId,
    }, accountId);

    const modelId = await this.add(Resource.Model, {
      name: 'Llama 3.1 8B',
      description: 'Llama 3.1 8B Model',
      engine: 'llamacpp',
      task: 'text-generation',
      inputType: BlockInputOutputType.Text,
      outputType: BlockInputOutputType.Text,
      url: 'https://huggingface.co/bartowski/Meta-Llama-3.1-8B-Instruct-GGUF/resolve/main/Meta-Llama-3.1-8B-Instruct-Q4_K_M.gguf',
    }, accountId);

    await this.add(Resource.Model, {
      name: 'Llama 3.2 3B',
      description: 'Llama 3.2 3B Model',
      engine: 'llamacpp',
      task: 'text-generation',
      inputType: BlockInputOutputType.Text,
      outputType: BlockInputOutputType.Text,
      url: 'https://huggingface.co/bartowski/Llama-3.2-3B-Instruct-GGUF/resolve/main/Llama-3.2-3B-Instruct-Q4_K_M.gguf',
    }, accountId);

    const agentId = await this.add(Resource.Agent, {
      name: 'Instructions Writer',
      description: 'Instructions Writer Agent',
      modelId,
      showSkillOutput: true,
      grammar: 'default',
      temperature: 0,
      maxTokens: 5000,
      instructions: instructionsWriter,
    }, accountId);

    const promptUid = v4();
    const agentUid = v4();
    const answerUid = v4();

    await this.add(Resource.Workflow, {
      name: 'Instructions Writer',
      description: 'Instructions Writer Workflow',
      showInitialPrompt: false,
      showSuggestedPrompts: false,
      showRelatedDocuments: true,
      supportsMultipleThreads: true,
      saveHistory: true,
      isPublic: false,
      showHeader: true,
      showDebugger: false,
      bucketId,
      vpmId,
      blocks: [
        {
          type: BlockType.Prompt,
          uid: promptUid,
          id: 'prompt',
          name: this.blocksDefinition.triggers['prompt'].workflow!.name,
          outputTypes: this.blocksDefinition.triggers['prompt'].workflow!.outputTypes,
          coordinates: { x: 70, y: 170 },
          links: [{
            type: BlockInputOutputType.Text,
            uid: agentUid,
          }],
        },
        {
          type: BlockType.Agent,
          uid: agentUid,
          id: agentId,
          name: this.blocksDefinition.agents['template'].workflow!.name + ' ' + 'Instructions Writer',
          inputTypes: this.blocksDefinition.agents['template'].workflow!.inputTypes,
          outputTypes: this.blocksDefinition.agents['template'].workflow!.outputTypes,
          version: '1',
          coordinates: { x: 330, y: 145 },
          links: [{
            type: BlockInputOutputType.Text,
            uid: answerUid,
          }],
        },
        {
          type: BlockType.Answer,
          uid: answerUid,
          id: 'answer',
          name: this.blocksDefinition.outputs['answer'].workflow!.name,
          inputTypes: this.blocksDefinition.outputs['answer'].workflow!.inputTypes,
          coordinates: { x: 600, y: 170 },
        },
      ]
    }, accountId);
  }
}
