import { Block, BlockInputOutputType } from 'src/app/models/blocks/base';

export function getAgentBlocks(): Block {
  return {
    template: {
      ingest: {
        name: 'Run',
        inputTypes: [BlockInputOutputType.Text],
        outputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Index],
      },
      workflow: {
        name: 'Run',
        inputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Wait, BlockInputOutputType.Context, BlockInputOutputType.Knowledge, BlockInputOutputType.Skill],
        outputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Wait, BlockInputOutputType.Context],
      },
    },
  };
}
