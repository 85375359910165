import { Routes } from '@angular/router';
import { inject } from '@angular/core';

import { AuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from 'src/app/guards/auth/auth.guard';
import { ObjectStoreCanDeactivateGuard } from 'src/app/guards/object-store/object-store-can-deactivate.guard';
import { PipelineCanDeactivateGuard } from 'src/app/guards/pipeline/pipeline-can-deactivate.guard';
import { RoleGuard } from 'src/app/guards/role/role.guard';

import { ObjectStoragePage } from 'src/app/pages/object-storage/object-storage.page';
import { EditorPage } from 'src/app/pages/editor/editor.page';

import { OrganizationRole } from 'src/app/services/organization-member/organization-member.service';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['overview']);
const allowedRoles = [OrganizationRole.Admin, OrganizationRole.Member];

export const routes: Routes = [
  {
    path: 'overview',
    loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'cluster/:id',
    loadComponent: () => import('./pages/home/home.page').then( m => m.HomePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'pubsub',
    loadComponent: () => import('./pages/pubsub/pubsub.page').then( m => m.PubsubPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'pubsub/:id',
    loadComponent: () => import('./pages/pubsub/pubsub.page').then( m => m.PubsubPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'object-storage',
    loadComponent: () => import('./pages/object-storage/object-storage.page').then( m => m.ObjectStoragePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'object-storage/:id',
    loadComponent: () => import('./pages/object-storage/object-storage.page').then( m => m.ObjectStoragePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles },
    canDeactivate: [(component: ObjectStoragePage) => inject(ObjectStoreCanDeactivateGuard).canDeactivate(component)]
  },
  {
    path: 'external-storage',
    loadComponent: () => import('./pages/external-storage/external-storage.page').then( m => m.ExternalStoragePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'external-storage/:id',
    loadComponent: () => import('./pages/external-storage/external-storage.page').then( m => m.ExternalStoragePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'key-value-database',
    loadComponent: () => import('./pages/key-value-database/key-value-database.page').then( m => m.KeyValueDatabasePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'key-value-database/:id',
    loadComponent: () => import('./pages/key-value-database/key-value-database.page').then( m => m.KeyValueDatabasePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'account',
    loadComponent: () => import('./pages/account/account.page').then( m => m.AccountPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'media-stream',
    loadComponent: () => import('./pages/media-stream/media-stream.page').then( m => m.MediaStreamPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'cloudlet',
    loadComponent: () => import('./pages/cloudlet/cloudlet.page').then( m => m.CloudletPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'cloudlet/:id',
    loadComponent: () => import('./pages/cloudlet/cloudlet.page').then( m => m.CloudletPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'mistlet',
    loadComponent: () => import('./pages/mistlet/mistlet.page').then( m => m.MistletPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'mistlet/:id',
    loadComponent: () => import('./pages/mistlet/mistlet.page').then( m => m.MistletPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'mistlet/:id/:mistletId',
    loadComponent: () => import('./pages/mistlet/mistlet.page').then( m => m.MistletPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'function',
    loadComponent: () => import('./pages/function/function.page').then( m => m.FunctionPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'virtual-private-mist',
    loadComponent: () => import('./pages/virtual-private-mist/virtual-private-mist.page').then( m => m.VirtualPrivateMistPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'virtual-private-mist/:id',
    loadComponent: () => import('./pages/virtual-private-mist/virtual-private-mist.page').then( m => m.VirtualPrivateMistPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'model',
    loadComponent: () => import('./pages/model/model.page').then( m => m.ModelPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'model/:id',
    loadComponent: () => import('./pages/model/model.page').then( m => m.ModelPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'workflow',
    loadComponent: () => import('./pages/workflow/workflow.page').then( m => m.WorkflowPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'workflow/:id',
    loadComponent: () => import('./pages/workflow/workflow.page').then( m => m.WorkflowPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'chat/:accountId/:vpmId/:workflowId',
    loadComponent: () => import('./pages/chat/chat.page').then( m => m.ChatPage)
  },
  {
    path: 'chat/:accountId/404',
    loadComponent: () => import('./pages/chat/chat.page').then( m => m.ChatPage)
  },
  {
    path: 'log-explorer/:workflowId',
    loadComponent: () => import('./pages/log-explorer/log-explorer.page').then( m => m.LogExplorerPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'agent',
    loadComponent: () => import('./pages/agent/agent.page').then( m => m.AgentPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'agent/:id',
    loadComponent: () => import('./pages/agent/agent.page').then( m => m.AgentPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'skill',
    loadComponent: () => import('./pages/skill/skill.page').then( m => m.SkillPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'skill/:id',
    loadComponent: () => import('./pages/skill/skill.page').then( m => m.SkillPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'grammar',
    loadComponent: () => import('./pages/grammar/grammar.page').then( m => m.GrammarPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'grammar/:id',
    loadComponent: () => import('./pages/grammar/grammar.page').then( m => m.GrammarPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'data-collection',
    loadComponent: () => import('./pages/data-collection/data-collection.page').then( m => m.DataCollectionPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'data-collection/:id',
    loadComponent: () => import('./pages/data-collection/data-collection.page').then( m => m.DataCollectionPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'editor/:mode/:id',
    loadComponent: () => import('./pages/editor/editor.page').then( m => m.EditorPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles },
    canDeactivate: [(component: EditorPage) => inject(PipelineCanDeactivateGuard).canDeactivate(component)]
  },
  {
    path: 'vector-database',
    loadComponent: () => import('./pages/vector-database/vector-database.page').then( m => m.VectorDatabasePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'vector-database/:id',
    loadComponent: () => import('./pages/vector-database/vector-database.page').then( m => m.VectorDatabasePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'graph-database',
    loadComponent: () => import('./pages/graph-database/graph-database.page').then( m => m.GraphDatabasePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'graph-database/:id',
    loadComponent: () => import('./pages/graph-database/graph-database.page').then( m => m.GraphDatabasePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'profile',
    loadComponent: () => import('./pages/profile/profile.page').then( m => m.ProfilePage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'create-organization',
    loadComponent: () => import('./pages/create-organization/create-organization.page').then(m => m.CreateOrganizationPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'organization',
    loadComponent: () => import('./pages/user-management/user-management.page').then( m => m.UserManagementPage),
    canActivate: [AuthGuard, RoleGuard], data: { authGuardPipe: redirectUnauthorizedToLogin, allowedRoles }
  },
  {
    path: 'organization-invitation',
    loadComponent: () => import('./pages/organization-invitation/organization-invitation.page').then( m => m.OrganizationInvitationPage),
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then( m => m.LoginPage),
    canActivate: [AuthGuard], data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: 'signup',
    loadComponent: () => import('./pages/signup/signup.page').then( m => m.SignupPage),
    canActivate: [AuthGuard], data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: 'organization-license',
    loadComponent: () => import('./pages/organization-license/organization-license.page').then( m => m.OrganizationLicensePage)
  },
  {
    path: 'aws-fulfillment',
    loadComponent: () => import('./pages/aws-fulfillment/aws-fulfillment.page').then( m => m.AwsFulfillmentPage)
  },
  {
    path: 'connect',
    loadComponent: () => import('./pages/connect/connect.page').then( m => m.ConnectPage)
  },
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full',
  },
];
