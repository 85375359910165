export async function runWithRetry(
  transactionFn: () => Promise<unknown>,
  attempt: number = 0,
  maxRetries: number = 3
): Promise<void> {
  try {
    await transactionFn();
  } catch (error) {
    if (attempt >= maxRetries) {
      throw error;
    }

    console.warn(`Transaction failed, retrying... Attempt ${attempt + 1}`, transactionFn, error);
    setTimeout(() => {
      runWithRetry(transactionFn, attempt + 1, maxRetries);
    }, Math.pow(2, attempt) * 1000); // Exponential backoff
  }
}
